<template lang="html">
<v-container fluid>
  <div v-if="checkRemedial == false">
    <lock v-if="currentDay != 6 && currentDay != 0 || checkRemedial == false" @status="bukaAktivitas"></lock>
  </div>

  <template v-if="open || currentDay == 6 || currentDay == 0 || checkRemedial">
    <v-alert
    :type="checkRemedial ? 'warning' : 'info'"
    v-if="responseData.sudah_review == 0"
    >{{checkRemedial ? 'Remedial review. Jumlah pegawai yang harus direview adalah' : 'Belum melakukan review. Jumlah pegawai yang harus direview adalah' }} {{responseData.harus_review}} orang</v-alert>
    <v-alert
    type="info"
    v-else-if="responseData.sudah_review > 0"
    >Sudah melakukan {{responseData.sudah_review}} review dari {{responseData.harus_review}} review</v-alert>
    <v-alert
    type="success"
    v-else-if="responseData.sudah_review == responseData.harus_review"
    >Sudah melakuan review. Jumlah review adalah {{responseData.sudah_review}}</v-alert>

    <v-card v-if="(responseData.struktur_pokja || responseData.struktur_pokja_anggota) == true ? true : false" class="pa-2" color="primary white--text">
      <h1 class="display-2 font-weight-bold" style="text-align:center;text-transform:uppercase">Review Perilaku Pokja</h1>
    </v-card>

    <v-row v-if="loadingPegawai">
      <v-col v-for="i in [1,2,3,,4,5]" :key="i">
        <v-skeleton-loader
        class="mx-auto"
        min-width="300"
        type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-if="pegawai.length > 0" class="mt-10">
      <v-col v-for="(peg,i) in pegawai" :key="i" align="center">
        <base-material-card
          class="v-card-profile"
          :avatar="peg.foto"
          style="width:270px; "
        >
          <v-card-text class="text-center" style="height:225px">
            <h4 class="display-2 mb-1 orange--text text--darken-2" style="height:55px;">
              {{peg.nama}}
            </h4>
            <h4 class="font-weight-light grey--text mb-2">
              NIP. {{peg.nip}}
            </h4>
            <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
              {{peg.jabatan}}
            </h4>
            <div class="mt-5">
              <v-chip small label :class="reviewStatus(peg)" class="ma-1" dark>{{peg.status}}</v-chip>
              <v-chip small label class="ma-1 orange darken-2" dark>{{peg.jumlah}}/{{peg.harus}} Pertanyaan</v-chip>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
            color="primary"
            rounded
            class="mr-0"
            @click="openDialog(peg.nip,i,peg)"
            :loading="loadingEl == i"
            :disabled="!peg.tanggal_review && !peg.remedial"
            :small="true"
            >
              <span v-if="peg.remedial && !peg.submit"><v-icon>mdi-pencil-box</v-icon> Review Ulang</span>
              <span v-else-if="!peg.submit"><v-icon left>mdi-pencil-box</v-icon> Review</span>
              <span v-else-if="peg.submit && (peg.jumlah < peg.harus)"><v-icon left>mdi-pencil-box</v-icon> Review Belum Lengkap</span>
              <span v-else><v-icon left>mdi-eye</v-icon>Lihat Jawaban</span>
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <v-col cols="5">
        <v-alert
        color="blue-grey"
        dark
        dense
        icon="mdi-account-group"
        prominent
      >
        Tidak ada pegawai yang harus di-<i>review</i>
      </v-alert>
      </v-col>
    </v-row>
  </template>


  <v-dialog v-model="dialog">
    <InputReviewPerilakuDetail :dataPegawai="dataPegawai" @onClose="(x)=>{dialog=x}"/>
  </v-dialog>
</v-container>
</template>

<script>
import InputReviewPerilakuDetail from './InputReviewPerilakuDetail'
import PelaksanaService from '@/services/PelaksanaService'
import Lock from './components/Lock'
import moment from 'moment'

export default {
  components:{
    InputReviewPerilakuDetail,
    Lock,
  },

  data:function(){
    return{
      responseData:{},
      pegawai:[],
      dialog: false,
      dataPegawai:{},
      loadingEl:-1,
      loadingPegawai:true,
      open:false,
      check_remedial: false
    }
  },

  computed:{
    checkRemedial(){
      return this.check_remedial
    },
    currentDay(){
      return moment().day()
    },
  },

  watch:{
    dialog(val){
      val || this.getData()
    }
  },

  created(){
    this.getData()
  },

  methods:{
    bukaAktivitas(val){
      console.log(val, this.open)
      if (val == 'open'){
        this.open = true
      } else {
        this.open = false
      }
    },

    getData(){
      this.loadingPegawai = true
      PelaksanaService.inputReviewGet().then(response=>{
        this.pegawai = response.data.data
        this.pegawai.forEach(el => {
          if(el.remedial){
            this.check_remedial = el.remedial
          }
        });
        this.responseData = response.data
      }).finally(()=>{
        this.loadingPegawai = false
      })
    },

    reviewStatus(peg){
      let status = peg.status ? peg.status.toLowerCase() : ''
      if(peg.submit && (peg.jumlah != peg.harus)){
          peg.status = 'Review belum lengkap'
      }

      return{
        red:status === 'belum direview',
        blue:status === 'on going',
        green: status === 'sudah direview',
        brown: peg.status === 'Review belum lengkap'
        // green:status == 'belum direview'
      }
    },

    openDialog(nip, idx, peg){
      this.loadingEl = idx
      PelaksanaService.inputReviewUlas(nip).then(response => {
        console.log(response.data)
        this.dataPegawai = response.data
        this.dataPegawai.jumlah = peg.jumlah
        this.dataPegawai.harus = peg.harus
        this.dialog=true
      }).finally(()=>{
        this.loadingEl = -1
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
